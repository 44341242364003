import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"
import {twMerge} from "tailwind-merge"
import {z} from "zod"

import {ButtonForm} from "../../../components/Button.tsx"
import {FieldErrorMessage} from "../../../components/fields/components.tsx"
import {RadioConnected, TRadioRenderComponentProps} from "../../../components/fields/RadioButton.tsx"
import {Flag} from "../../../components/Flag.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {useCreateSalesCycleMutation, useUpdateSalesCycleMutation} from "../../../queries/salesCycles.ts"
import {queryKey} from "../../../services"
import {useIsWelcomeScreenEnabled} from "../../../utils"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.tsx"
import {useCountries, useDocumentTitle, useNumParam} from "../../../utils/hooks.tsx"
import {setFormErrorsFromAxios, validateNonemptyString} from "../../../utils/validation.ts"
import {OnboardingLayout} from "../components/OnboardingLayout.tsx"
import {ECreateSalesCyclePage, getCreateSellingLink} from "../utils.ts"

const validationSchema = z.object({
  country: validateNonemptyString(),
})

export const CountryPage: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("CreateSalesCycle_Country_DocumentTitle"))

  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()

  const countries = useCountries()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const salesCycleId = useNumParam("salesCycleId", true)

  const methods = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
  })
  const {getValues, setError} = methods

  const createSalesCycleMutation = useCreateSalesCycleMutation()
  const updateSalesCycleMutation = useUpdateSalesCycleMutation()

  const handleSubmitCreate = React.useCallback(async () => {
    const values = getValues()
    const countryId = countries.find(country => country.code === values.country)?.id

    if (countryId == null) {
      return
    }

    try {
      const response = await createSalesCycleMutation.mutateAsync({countryId})

      isWelcomeScreenEnabled && emitGTMEvent({event: EGTMEvent.COMPANY_COUNTRY_SELECTED})

      navigate(
        getCreateSellingLink({salesCycleId: response.data.sales_cycle.id, page: ECreateSalesCyclePage.SALESPEOPLE})
      )
    } catch (e) {
      setFormErrorsFromAxios(e, setError, "sales_cycle")
    }
  }, [countries, createSalesCycleMutation, getValues, isWelcomeScreenEnabled, navigate, setError])

  const handleSubmitUpdate = React.useCallback(async () => {
    const values = getValues()
    const countryId = countries.find(country => country.code === values.country)?.id

    if (!salesCycleId || countryId == null) {
      return
    }

    queryClient.resetQueries({queryKey: queryKey.salesCyclePeople(salesCycleId)})

    try {
      await updateSalesCycleMutation.mutateAsync({
        id: salesCycleId,
        data: {country_id: countryId},
      })
      navigate(getCreateSellingLink({salesCycleId, page: ECreateSalesCyclePage.SALESPEOPLE}))
    } catch (e) {
      setFormErrorsFromAxios(e, setError, "sales_cycle")
    }
  }, [countries, getValues, navigate, queryClient, salesCycleId, setError, updateSalesCycleMutation])

  const handleSubmit = React.useCallback(async () => {
    return await (salesCycleId ? handleSubmitUpdate : handleSubmitCreate)()
  }, [handleSubmitCreate, handleSubmitUpdate, salesCycleId])

  const countryOptions = React.useMemo(() => {
    return countries
      .filter(country => country.sales_cycle_allowed)
      .map(country => ({value: country.code, title: country.name}))
  }, [countries])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className={"contents"}>
        <OnboardingLayout
          title={t("CreateSalesCycle_Country_Title")}
          subtitle={t("CreateSalesCycle_Country_Subtitle")}
          step={1}
          total={3}
          rightAction={<ButtonForm>{t("CreateSalesCycle_Country_SubmitButton")}</ButtonForm>}
        >
          <LayoutBlock innerClassName={"flex flex-col gap-8"} outerClassName={"pt-7"}>
            <div className={"flex flex-wrap gap-8"}>
              {countryOptions.map(country => (
                <RadioConnected
                  value={country.value}
                  name={"country"}
                  key={country.value}
                  RenderComponent={CountryRadioRenderer}
                >
                  <Flag code={country.value} className={"cr-shadow w-20 rounded-lg"} />
                  <h3 className={"font-bold"}>{country.title}</h3>
                </RadioConnected>
              ))}
            </div>
            <FieldErrorMessage name={"country"} />
          </LayoutBlock>
        </OnboardingLayout>
      </form>
    </FormProvider>
  )
}

const CountryRadioRenderer: React.FC<TRadioRenderComponentProps> = ({checked, hasError, children}) => {
  return (
    <div
      className={twMerge(
        "card-shadow flex h-48 w-44 select-none flex-col items-center justify-center gap-4 transition-colors",
        hasError && "border-cr-red",
        checked && "border-2 border-cr-blue"
      )}
    >
      {children}
    </div>
  )
}
