import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {formatDistanceToNowStrict} from "date-fns/formatDistanceToNowStrict"
import {twMerge} from "tailwind-merge"

import {Badge} from "../../components/Badge.tsx"
import {ProfileImage} from "../../components/ProfileImage.tsx"
import {Table as LayoutTable} from "../../components/Table/Table.tsx"
import {getColumnsFromMeta} from "../../components/Table/utils/columns.ts"
import {TColumnsMetaWithEmpty} from "../../components/Table/utils/shared.ts"
import {useLSTableColumnsState} from "../../components/Table/utils/useLSTableColumnsState.tsx"
import {getCurrentLanguage} from "../../i18n.ts"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {
  ADashboardStages,
  ASaasCompanyUserDashboardSalesCycle,
  ASaasCompanyUsersDashboardAssignments,
  AUserTypes,
} from "../../services/types.generated.ts"
import {getFullName} from "../../utils"
import {apiDateToJS, getDateFnsLocale} from "../../utils/dateArithmetics.ts"
import {getCurrentStage, getStageIndex, getStageTimings} from "../DashboardSalesperson/Selling.tsx"
import {getTableBadgeColor, TSalesPlansTableColumn} from "./utils.ts"

const tableId = "saas/dashboard"

export const Table: FC<{date: string; salesCycle: ASaasCompanyUserDashboardSalesCycle}> = ({salesCycle, date}) => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()

  const timings = getStageTimings(apiDateToJS(date))
  const isFinished = timings[timings.length - 1].end < new Date()
  const currentStage = getCurrentStage(apiDateToJS(date))
  const currentStageIndex = isFinished ? Infinity : getStageIndex(currentStage?.stage)

  const rows = React.useMemo(() => {
    const thisUserIndex = (salesCycle.assignments ?? []).findIndex(
      assignment => assignment.sales_person?.id === user.id
    )

    if (user.type !== AUserTypes.SaasSalesPerson || !salesCycle.assignments || thisUserIndex === -1) {
      return salesCycle.assignments
    }

    return [salesCycle.assignments[thisUserIndex], ...salesCycle.assignments.toSpliced(thisUserIndex, 1)]
  }, [salesCycle.assignments, user.id, user.type])

  const isCurrentStage = React.useCallback(
    (stage: ADashboardStages) => {
      return currentStage?.stage === stage
    },
    [currentStage?.stage]
  )

  const isFutureStage = React.useCallback(
    (stage: ADashboardStages) => {
      return currentStageIndex < getStageIndex(stage)
    },
    [currentStageIndex]
  )

  const columnsMeta = React.useMemo<
    TColumnsMetaWithEmpty<TSalesPlansTableColumn, ASaasCompanyUsersDashboardAssignments>
  >(() => {
    return [
      {
        column: "salesperson",
        size: "max-content",
        HeaderCellValue: () => t("Dashboard_SaaS_Table_SalesTalent"),
        CellValue: ({row}) =>
          row.sales_person ? (
            <div title={getFullName(row.sales_person)} className={"flex items-center gap-2"}>
              <ProfileImage
                className={"size-6 shrink-0"}
                src={row.sales_person.profile_picture_thumbnail_url ?? undefined}
              />
              <div className={"overflow-hidden text-ellipsis font-medium"}>{getFullName(row.sales_person)}</div>
            </div>
          ) : null,
      },
      {
        column: "prospects",
        align: "right",
        HeaderCellValue: () => t("Dashboard_SaaS_Table_Prospects"),
        CellValue: ({row}) =>
          [row.prospects_in_crm ?? 0, row.statistics.opportunities || null].filter(n => n != null).join(" + ") ?? "-",
      },
      {
        column: "emailing",
        align: "right",
        HeaderCellValue: () => (
          <span className={twMerge(isCurrentStage(ADashboardStages.FirstOutreach) && "text-cr-blue")}>
            {t("Dashboard_SaaS_Table_FirstOutreach")}
          </span>
        ),
        CellValue: ({row}) =>
          isFutureStage(ADashboardStages.FirstOutreach) ? (
            (row.statistics.emails_sent_activities_uniq ?? 0)
          ) : (
            <Badge
              color={getTableBadgeColor({
                stage: ADashboardStages.FirstOutreach,
                currentStage: currentStage?.stage,
                assignment: row,
              })}
              className={"font-normal"}
            >
              {row.statistics.emails_sent_activities_uniq ?? 0} / {row.reaches}
            </Badge>
          ),
      },
      {
        column: "calling",
        align: "right",
        HeaderCellValue: () => (
          <span className={twMerge(isCurrentStage(ADashboardStages.SecondOutreach) && "text-cr-blue")}>
            {t("Dashboard_SaaS_Table_SecondOutreach")}
          </span>
        ),
        CellValue: ({row}) =>
          isFutureStage(ADashboardStages.SecondOutreach) ? (
            (row.statistics.calls_made_activities_uniq ?? 0)
          ) : (
            <Badge
              color={getTableBadgeColor({
                stage: ADashboardStages.SecondOutreach,
                currentStage: currentStage?.stage,
                assignment: row,
              })}
              className={"font-normal"}
            >
              {row.statistics.calls_made_activities_uniq ?? 0} / {row.reaches}
            </Badge>
          ),
      },
      {
        column: "meetings",
        align: "right",
        HeaderCellValue: () => (
          <span className={twMerge(isCurrentStage(ADashboardStages.PresentationDemo) && "text-cr-blue")}>
            {t("Dashboard_SaaS_Table_PresentationDemo")}
          </span>
        ),
        CellValue: ({row}) =>
          isFutureStage(ADashboardStages.PresentationDemo) ? (
            (row.statistics.meetings_done_activities ?? 0)
          ) : (
            <Badge
              color={getTableBadgeColor({
                stage: ADashboardStages.PresentationDemo,
                currentStage: currentStage?.stage,
                assignment: row,
              })}
              className={"font-normal"}
            >
              {row.statistics.meetings_done_activities ?? 0} / {row.minimum_meetings_count}
            </Badge>
          ),
      },
      {
        column: "won",
        align: "right",
        HeaderCellValue: () => t("Dashboard_SaaS_Table_Won"),
        CellValue: ({row}) => row.statistics.won ?? 0,
      },
      {
        column: "price offer",
        align: "right",
        HeaderCellValue: () => t("Dashboard_SaaS_Table_PriceOffer"),
        CellValue: ({row}) => row.statistics.price_offers ?? 0,
      },
      {
        column: "last activity",
        HeaderCellValue: () => t("Dashboard_SaaS_Table_LastActivity"),
        CellValue: ({row}) => {
          return row.last_activity_date ? (
            <span
              title={apiDateToJS(row.last_activity_date).toLocaleString(getCurrentLanguage(), {
                dateStyle: "medium",
                timeStyle: "medium",
              })}
            >
              {formatDistanceToNowStrict(apiDateToJS(row.last_activity_date), {...getDateFnsLocale(), addSuffix: true})}
            </span>
          ) : (
            "-"
          )
        },
      },
    ]
  }, [currentStage?.stage, isCurrentStage, isFutureStage, t])

  const columnsState = useLSTableColumnsState(tableId, {
    columnsOrder: getColumnsFromMeta(columnsMeta),
    pinnedColumn: "salesperson",
  })

  return columnsMeta && rows ? (
    <LayoutTable<TSalesPlansTableColumn, ASaasCompanyUsersDashboardAssignments>
      {...columnsState}
      className={"bg-cr-white"}
      columnsMeta={columnsMeta}
      data={rows}
    />
  ) : null
}
