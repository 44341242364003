import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock"
import {Loading} from "../../../components/Loading"
import {EOrderDirection} from "../../../components/Table/utils/shared.ts"
import {sortDate} from "../../../components/Table/utils/sortFunctions.ts"
import {getCurrentLanguage} from "../../../i18n"
import {
  TSalespersonProspectsAssignment,
  TSalespersonProspectsIteration,
  useDraftTableQuery,
  useFinishedTableQuery,
  useProspectsSalesCycleQuery,
  useWaitingForApprovalTableQuery,
} from "../../../queries/prospects"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {ASalesPersonProspectsSalesCycle} from "../../../services/types.generated"
import {CombineProviders} from "../../../utils/context.tsx"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks"
import {isSalesPersonUser} from "../../../utils/types.ts"
import {PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {StatusBanner} from "../Admin/components/StatusBanner.tsx"
import {AddProspectsModal} from "../shared/AddProspectsModal"
import {EFeature} from "../shared/AddProspectsModal/utils.ts"
import {AssignmentContext, DeletingContext, EditingContext, IsUploadingContext} from "../shared/context"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {NoProspects} from "../shared/NoProspects.tsx"
import {getActiveIteration, salesCycleRemoveIterationsWithoutAssignments} from "../shared/utils.ts"
import {Section} from "./Section"

export const SalespersonProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {data, error, refetch} = useProspectsSalesCycleQuery(salesCycleId)

  const salesCycle = React.useMemo(() => {
    if (!data) {
      return null
    }

    return salesCycleRemoveIterationsWithoutAssignments(data)
  }, [data])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <ProspectsLoaded salesCycle={salesCycle as ASalesPersonProspectsSalesCycle} />
}

export const ProspectsLoaded: React.FC<{
  salesCycle: ASalesPersonProspectsSalesCycle
}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()
  const isLeadsEnabled = isSalesPersonUser(user) && !!user.leads_enabled

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const assignmentContextValue = AssignmentContext.useProviderValue({
    assignment: null,
    iteration: getActiveIteration(salesCycle),
    salesCycle,
  })
  const {setValue: setAssignmentContext} = assignmentContextValue

  React.useEffect(() => {
    setAssignmentContext(ctx => ({...ctx, salesCycle}))
  }, [salesCycle, setAssignmentContext])

  const activeIteration = (assignmentContextValue.value?.iteration as TSalespersonProspectsIteration) ?? null
  const activeAssignment = (assignmentContextValue.value?.assignment as TSalespersonProspectsAssignment) ?? null

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(
        sortDate<TSalespersonProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC)
      )
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      setAssignmentContext(currentValue => ({
        ...currentValue,
        iteration: salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate) ?? null,
      }))
    },
    [salesCycle, setAssignmentContext]
  )

  if (!activeIteration || !activeAssignment) {
    return <Loading />
  }

  const hasNoProspects =
    activeAssignment.prospects_draft_count === 0 &&
    activeAssignment.prospects_ready_to_approve_count === 0 &&
    activeAssignment.prospects_finished_count === 0

  return (
    <CombineProviders
      providers={[
        AssignmentContext.combined(assignmentContextValue),
        IsUploadingContext.combined(isUploadingContextValue),
        DeletingContext.combined(deleteContextValue),
        EditingContext.combined(editingContextValue),
      ]}
    >
      <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9"}>
        <div className={"flex items-start justify-between"}>
          <PeriodToggle
            periods={iterationOptions}
            value={activeIteration.start_date}
            onChange={handleChangeIteration}
            noLifetime
          />

          <StatusBanner iteration={activeIteration} />
        </div>

        {hasNoProspects && <NoProspects />}

        {activeAssignment.prospects_draft_count > 0 && (
          <Section
            title={t("Prospects_Draft_Title", {count: activeAssignment.prospects_draft_count})}
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            isAddButtonVisible
            isSendButtonVisible
            isPrioritizable={false}
            listQuery={useDraftTableQuery}
            paginationKey={"prospects salesperson draft"}
          />
        )}
        {activeAssignment.prospects_ready_to_approve_count > 0 && (
          <Section
            title={
              <>
                {t("Prospects_WaitingForApproval_Title", {
                  count: activeAssignment.prospects_ready_to_approve_count,
                })}
                {activeAssignment.prospects_sent_for_approval_at && (
                  <span className={"ml-2 text-sm font-normal"}>
                    {t("Prospects_WaitingForApproval_Subtitle", {
                      date: apiDateToJS(activeAssignment.prospects_sent_for_approval_at).toLocaleString(
                        getCurrentLanguage(),
                        {month: "long", day: "numeric"}
                      ),
                      time: apiDateToJS(activeAssignment.prospects_sent_for_approval_at).toLocaleString(
                        getCurrentLanguage(),
                        {timeStyle: "short"}
                      ),
                    })}
                  </span>
                )}
              </>
            }
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            isAddButtonVisible={activeAssignment.prospects_draft_count === 0}
            isReadOnly
            listQuery={useWaitingForApprovalTableQuery}
            paginationKey={"prospects salesperson waiting for approval"}
          />
        )}
        {activeAssignment.prospects_finished_count > 0 && (
          <Section
            title={
              <>
                {t("Prospects_Finished_Title", {count: activeAssignment.prospects_finished_count})}
                <span className={"ml-2 text-sm font-normal"}>
                  {t("Prospects_Finished_Subtitle", {
                    approved: activeAssignment.prospects_approved_count,
                    rejected: activeAssignment.prospects_finished_count - activeAssignment.prospects_approved_count,
                  })}
                </span>
              </>
            }
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            isAddButtonVisible={
              activeAssignment.prospects_draft_count === 0 && activeAssignment.prospects_ready_to_approve_count === 0
            }
            isRejectedOnlyToggleVisible
            isReadOnly
            listQuery={useFinishedTableQuery}
            paginationKey={"prospects salesperson finished"}
          />
        )}
      </LayoutBlock>

      <EditingFlyout />
      <DeleteModal />
      <AddProspectsModal
        enabledFeatures={[EFeature.Single, isLeadsEnabled && EFeature.Leads].filter(feature => !!feature)}
      />
    </CombineProviders>
  )
}
