import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {BuildingOffice2Icon, TrashIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {EmailCell, isAnonymized, PhoneNumberCell} from "../../components/Anonymized.tsx"
import {ButtonLoading, IconButton} from "../../components/Button"
import {CheckboxBase} from "../../components/fields/Checkbox"
import {InputBase} from "../../components/fields/Input"
import {DropdownBase} from "../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../components/formElements/Dropdown/types"
import {Link} from "../../components/Link.tsx"
import {Table} from "../../components/Table/Table.tsx"
import {TableRow, TTableRowIsClickDisabled, TTableRowOnClick} from "../../components/Table/TableRow.tsx"
import {getColumnsFromMeta} from "../../components/Table/utils/columns.ts"
import {rowCheckColumn} from "../../components/Table/utils/rowChecking.tsx"
import {TColumnsMetaWithEmpty} from "../../components/Table/utils/shared.ts"
import {useLSTableColumnsState} from "../../components/Table/utils/useLSTableColumnsState.tsx"
import {Tooltip} from "../../components/Tooltip.tsx"
import {
  useAddProspectFromLeadMutation,
  useBulkAddProspectFromLeadMutation,
  useBulkRemoveProspectFromLeadMutation,
  useRemoveProspectFromLeadMutation,
} from "../../queries/leads"
import requestError from "../../services/requestError.tsx"
import {ALead, ALeadWithAssignmentProspect} from "../../services/types.generated"
import {addHttpToURL, getFullName} from "../../utils"
import {commonTransComponents, enumTranslKey} from "../../utils/i18n"
import {AssignmentContext} from "../Prospects/shared/context.ts"
import {DeletingContext, EditingContext, FilteringContext} from "./context"
import {CompanySizeFilter, CountryFilter, PositionFilter, SegmentFilter} from "./Filters.tsx"

export type TLeadsTableColumn =
  | "actions"
  | "in use"
  | "company"
  | "website"
  | "company size"
  | "segment"
  | "country"
  | "contact person"
  | "position"
  | "phone"
  | "email"

enum EBulkAction {
  UNSELECT,
  DELETE,
  ADD,
  REMOVE,
}

const tableId = "leads"

export const DataTable: React.FC<{
  isInProspects?: boolean
  data: Array<ALead | ALeadWithAssignmentProspect>
  isLoading: boolean
  refetch: () => void
}> = ({isInProspects, data, isLoading}) => {
  const {t} = useTranslation()

  const editContext = EditingContext.useContext()
  const deleteContext = DeletingContext.useContext()
  const assignmentContext = AssignmentContext.useOptionalContext()

  const {
    clearRows,
    checkedRows,
    orderBy,
    setOrderBy,
    checkRow,
    searchString,
    isSelectedOnly,
    companySizes,
    segments,
    positions,
    countries,
  } = FilteringContext.useContext()

  const bulkAddProspectFromLeadMutation = useBulkAddProspectFromLeadMutation()
  const bulkRemoveProspectFromLeadMutation = useBulkRemoveProspectFromLeadMutation()

  const handleChangeSearchString = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      searchString.setValue(e.target.value)
    },
    [searchString]
  )

  const bulkActionOptions = React.useMemo<Array<TOption<EBulkAction>>>(
    () => [
      {title: t("Leads_BulkAction_Unselect"), value: EBulkAction.UNSELECT},
      ...(isInProspects
        ? [
            {title: t("Leads_BulkAction_Add"), value: EBulkAction.ADD},
            {title: t("Leads_BulkAction_Remove"), value: EBulkAction.REMOVE},
          ]
        : [{title: t("Leads_BulkAction_Delete"), value: EBulkAction.DELETE}]),
    ],
    [isInProspects, t]
  )

  const handleBulkAction = React.useCallback(
    async (action: EBulkAction | undefined) => {
      switch (action) {
        case EBulkAction.UNSELECT:
          clearRows()
          break
        case EBulkAction.DELETE:
          deleteContext.setValue(checkedRows as number[])
          break
        case EBulkAction.ADD:
          if (!assignmentContext?.value?.assignment) {
            return
          }

          try {
            const response = await bulkAddProspectFromLeadMutation.mutateAsync({
              assignmentId: assignmentContext.value.assignment.id,
              ids: checkedRows as number[],
            })

            const processed = response.data.processed_count
            const skipped = response.data.skipped_count

            if (processed > 0 && skipped === 0) {
              // all processed
              toast.success(t("Prospects_ImportModal_LeadsStep_Add_Bulk_AllProcessed", {count: processed}))
            }
            if (processed > 0 && skipped > 0) {
              // some processed, some skipped
              toast.success(
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Add_Bulk_SomeProcessed"}
                  components={commonTransComponents}
                  values={{
                    processed,
                    skipped,
                  }}
                />
              )
            }
            if (processed === 0) {
              // all skipped
              toast.warning(t("Prospects_ImportModal_LeadsStep_Add_Bulk_AllSkipped"))
            }
          } catch (e) {
            requestError(e)
          }
          break
        case EBulkAction.REMOVE:
          if (!assignmentContext?.value?.assignment) {
            return
          }

          try {
            const response = await bulkRemoveProspectFromLeadMutation.mutateAsync({
              assignmentId: assignmentContext.value.assignment.id,
              ids: checkedRows as number[],
            })
            const processed = response.data.processed_count
            const skipped = response.data.skipped_count

            if (processed > 0 && skipped === 0) {
              // all processed
              toast.success(t("Prospects_ImportModal_LeadsStep_Remove_Bulk_AllProcessed", {count: processed}))
            }
            if (processed > 0 && skipped > 0) {
              // some processed, some skipped
              toast.success(
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Remove_Bulk_SomeProcessed"}
                  components={commonTransComponents}
                  values={{
                    processed,
                    skipped,
                  }}
                />
              )
            }
            if (processed === 0) {
              // all skipped
              toast.warning(t("Prospects_ImportModal_LeadsStep_Remove_Bulk_AllSkipped"))
            }
          } catch (e) {
            requestError(e)
          }
          break
      }
    },
    [
      assignmentContext?.value,
      bulkAddProspectFromLeadMutation,
      bulkRemoveProspectFromLeadMutation,
      checkedRows,
      clearRows,
      deleteContext,
      t,
    ]
  )

  const columnsMeta = React.useMemo<TColumnsMetaWithEmpty<TLeadsTableColumn, (typeof data)[number]>>(
    () => [
      rowCheckColumn,
      {
        column: "actions",
        size: "min-content",
        HeaderCellValue: () => t("Leads_Table_Actions"),
        CellValue: isInProspects ? ActionsProspects : ActionsLeads,
      },
      {
        column: "company",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Company"),
        CellValue: ({row}) => row.organization_name,
      },
      data.some(row => row.in_use) && {
        column: "in use",
        size: "min-content",
        HeaderCellValue: () => t("Leads_Table_InUse"),
        CellValue: ({row}) => row.in_use && <BuildingOffice2Icon className={"size-4"} />,
      },
      {
        column: "website",
        HeaderCellValue: () => t("Leads_Table_Website"),
        CellValue: ({row}) =>
          row.website ? (
            <Link to={addHttpToURL(row.website)} target={"_blank"} flipUnderline>
              {row.website}
            </Link>
          ) : (
            "-"
          ),
      },
      {
        column: "company size",
        HeaderCellValue: () => t("Leads_Table_CompanySize"),
        CellValue: ({row}) => t(enumTranslKey("CompanySize", row.company_size)),
        isFiltered: () => companySizes.isActive,
        clearFilter: companySizes.clear,
        FilterContent: CompanySizeFilter,
      },
      {
        column: "segment",
        size: "max-content",
        HeaderCellValue: () => t("Leads_Table_Segment"),
        CellValue: ({row}) => (
          <span>{row.segment?.map(segment => t(enumTranslKey("Segment", segment), segment)).join(", ") ?? "-"}</span>
        ),
        isFiltered: () => segments.isActive,
        clearFilter: segments.clear,
        FilterContent: SegmentFilter,
      },
      {
        column: "country",
        size: "minmax(min-content, 400px)",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Country"),
        CellValue: ({row}) => <span>{[row.city, row.country?.name].filter(Boolean).join(", ") || "-"}</span>,
        isFiltered: () => countries.isActive,
        clearFilter: countries.clear,
        FilterContent: CountryFilter,
      },
      {
        column: "contact person",
        size: "max-content",
        HeaderCellValue: () => t("Leads_Table_ContactPerson"),
        CellValue: ({row}) => (
          <span>
            {getFullName({first_name: row.contact_person_first_name, last_name: row.contact_person_last_name}) || "-"}
          </span>
        ),
      },
      {
        column: "position",
        size: "minmax(150px, auto)",
        sortFn: true,
        HeaderCellValue: () => t("Leads_Table_Position"),
        isFiltered: () => positions.isActive,
        clearFilter: positions.clear,
        FilterContent: PositionFilter,
      },
      {
        column: "phone",
        size: "max-content",
        HeaderCellValue: () => t("Leads_Table_Phone"),
        CellValue: PhoneNumberCell,
      },
      {column: "email", sortFn: true, HeaderCellValue: () => t("Leads_Table_Email"), CellValue: EmailCell},
    ],
    [
      companySizes.clear,
      companySizes.isActive,
      countries.clear,
      countries.isActive,
      data,
      isInProspects,
      positions.clear,
      positions.isActive,
      segments.clear,
      segments.isActive,
      t,
    ]
  )

  const columnsState = useLSTableColumnsState(tableId, {
    columnsOrder: getColumnsFromMeta(columnsMeta),
    pinnedColumn: "company",
  })

  const handleRowClick = React.useCallback<TTableRowOnClick<(typeof data)[number]>>(
    row => {
      editContext.setValue.call(null, row)
    },
    [editContext.setValue]
  )
  const isClickDisabled = React.useCallback<TTableRowIsClickDisabled<(typeof data)[number]>>(
    row => {
      return isInProspects || isAnonymized(row)
    },
    [isInProspects]
  )

  return (
    <div className={"flex flex-col gap-8"}>
      <div className={"flex flex-wrap-reverse items-center justify-between gap-8"}>
        <div className={"flex items-center gap-4"}>
          <div className={"min-w-[160px]"}>
            <DropdownBase
              options={bulkActionOptions}
              placeholder={t("Leads_BulkAction_Placeholder", {count: checkedRows.length})}
              value={null}
              onChange={handleBulkAction}
              disabled={checkedRows.length === 0}
            />
          </div>
          <div
            className={twMerge(
              "pointer-events-none opacity-40 transition-all",
              checkedRows.length && "pointer-events-auto opacity-100"
            )}
          >
            <CheckboxBase
              onChange={e => isSelectedOnly.setValue(e.target.checked)}
              toggle
              checked={isSelectedOnly.value}
            >
              {t("Leads_ShowSelectedOnly")}
            </CheckboxBase>
          </div>
        </div>
        <div
          className={twMerge(
            "flex grow flex-wrap items-center justify-end gap-6 opacity-100 transition-all",
            isSelectedOnly.value && "pointer-events-none opacity-40"
          )}
        >
          <InputBase
            value={searchString.value}
            onChange={handleChangeSearchString}
            placeholder={t("SearchPlaceholder")}
          />
        </div>
      </div>

      <Table<TLeadsTableColumn, (typeof data)[number]>
        {...columnsState}
        data={data}
        loading={isLoading || bulkAddProspectFromLeadMutation.isPending || bulkRemoveProspectFromLeadMutation.isPending}
        orderBy={orderBy}
        onOrder={setOrderBy}
        checkedRows={checkedRows}
        onCheckRow={checkRow}
        columnsMeta={columnsMeta}
      >
        {({data, pinnedColumn}) => (
          <>
            {data.length ? (
              data.map((row, index) => (
                <TableRow
                  key={row.id}
                  onClick={handleRowClick}
                  isClickDisabled={isClickDisabled}
                  row={row}
                  rowIndex={index}
                  className={twMerge([row.id === editContext.value?.id && "bg-cr-blue-super-light"])}
                  pinnedColumn={pinnedColumn}
                />
              ))
            ) : (
              <div className={"col-span-full py-7 text-center text-sm"}>{t("Leads_NoLeads_TableCaption")}</div>
            )}
          </>
        )}
      </Table>
    </div>
  )
}

export const ActionsLeads: React.FC<{row: ALead}> = ({row}) => {
  const {t} = useTranslation()
  const deleteContext = DeletingContext.useContext()

  return (
    <Tooltip
      buttonNode={
        <IconButton className={"text-cr-black hover:text-cr-red"} noEdges onClick={() => deleteContext.setValue(row)}>
          <TrashIcon className={"size-4"} />
        </IconButton>
      }
      openDelay={200}
    >
      <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>{t("Leads_Table_Delete")}</div>
    </Tooltip>
  )
}

export const ActionsProspects: React.FC<{row: ALeadWithAssignmentProspect}> = ({row}) => {
  const {t} = useTranslation()

  const assignmentContext = AssignmentContext.useContext()
  const assignment = assignmentContext.value?.assignment

  const addProspectFromLeadMutation = useAddProspectFromLeadMutation()
  const removeProspectFromLeadMutation = useRemoveProspectFromLeadMutation()

  const handleAdd = React.useCallback(async () => {
    if (!assignment) {
      return
    }

    try {
      await addProspectFromLeadMutation.mutateAsync({
        assignmentId: assignment.id,
        leadId: row.id,
      })
    } catch (e) {
      requestError(e)
    }
  }, [addProspectFromLeadMutation, assignment, row.id])

  const handleRemove = React.useCallback(async () => {
    if (row.assignment_prospect?.id == null) {
      return
    }

    try {
      await removeProspectFromLeadMutation.mutateAsync(row.assignment_prospect?.id)
    } catch (e) {
      requestError(e)
    }
  }, [removeProspectFromLeadMutation, row.assignment_prospect?.id])

  return row.assignment_prospect ? (
    <ButtonLoading size={"xs"} fullWidth onClick={handleRemove}>
      {t("Prospects_ImportModal_LeadsStep_Remove_Button")}
    </ButtonLoading>
  ) : (
    <ButtonLoading variant={"outlined"} color={"gray"} size={"xs"} fullWidth onClick={handleAdd}>
      {t("Prospects_ImportModal_LeadsStep_Add_Button")}
    </ButtonLoading>
  )
}
