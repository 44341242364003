import {skipToken, useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {AxiosResponse} from "axios"

import {usePaginatedQuery} from "../components/Pagination"
import {EOrderDirection} from "../components/Table/utils/shared.ts"
import {i18n} from "../i18n.ts"
import {TLeadsTableColumn} from "../routes/Leads/DataTable"
import {TLeadsFiltering} from "../routes/Leads/useLeadsFiltering.ts"
import api, {
  leadsGeneralTableKey,
  leadsImportersGeneralKey,
  multipartRequest,
  prospectsGeneralTablesKey,
  queryKey,
} from "../services"
import {TMetaWithWarnings} from "../services/types"
import {
  ALeadAttributes,
  ALeadAutocompleteFields,
  ALeadDeleteStrategies,
  ALeadDetail,
  ALeadSortByValues,
  ALeadSortDirectionValues,
  AOwnershipLevels,
  AUserTypes,
} from "../services/types.generated"
import {enumTranslKey} from "../utils/i18n.tsx"
import {useValueByUserType} from "../utils/userTypes.ts"
import {useRollbackImporterMutation} from "./importers"
import {useDeleteProspectMutation} from "./prospects"

function columnToApi(column?: TLeadsTableColumn): ALeadSortByValues | undefined {
  switch (column) {
    case "country":
      return ALeadSortByValues.City
    case "email":
      return ALeadSortByValues.Email
    case "company":
      return ALeadSortByValues.OrganizationName
    case "position":
      return ALeadSortByValues.Position
    case undefined:
      return undefined
  }
}
function directionToApi(direction?: EOrderDirection): ALeadSortDirectionValues | undefined {
  switch (direction) {
    case EOrderDirection.ASC:
      return ALeadSortDirectionValues.Asc
    case EOrderDirection.DESC:
      return ALeadSortDirectionValues.Desc
    case undefined:
      return undefined
  }
}

export const useLeadsIndexQuery = ({
  pageSize,
  ...filter
}: {
  pageSize: number
} & TLeadsFiltering) => {
  return usePaginatedQuery(
    queryKey.leads(filter),
    query =>
      api.leads.leadsList({
        search: filter.searchString.valueDebounced,
        "country_ids[]": filter.countries.valueDebounced.map(opt => opt.value),
        "positions[]": filter.positions.valueDebounced.map(opt => opt.value),
        "segments[]": filter.segments.valueDebounced.map(opt => opt.value),
        "company_sizes[]": filter.companySizes.valueDebounced.map(opt => opt.value),
        "ids[]": filter.checkedRowsForQuery as number[],
        sort_by: columnToApi(filter.orderBy?.column),
        sort_direction: directionToApi(filter.orderBy?.direction),
        ...query,
      }),
    {pageSize}
  )
}

export const useRemovalRequestedLeadsIndexQuery = ({
  pageSize,
  ...filter
}: {
  pageSize: number
} & TLeadsFiltering) =>
  usePaginatedQuery(
    queryKey.removalRequestedLeads(filter),
    query =>
      api.leads.removalRequestsIndexList({
        search: filter.searchString.valueDebounced,
        "country_ids[]": filter.countries.valueDebounced.map(opt => opt.value),
        "positions[]": filter.positions.valueDebounced.map(opt => opt.value),
        "segments[]": filter.segments.valueDebounced.map(opt => opt.value),
        "company_sizes[]": filter.companySizes.valueDebounced.map(opt => opt.value),
        "ids[]": filter.checkedRowsForQuery as number[],
        sort_by: columnToApi(filter.orderBy?.column),
        sort_direction: directionToApi(filter.orderBy?.direction),
        ...query,
      }),
    {pageSize}
  )

export const useLeadsForAssignmentQuery = ({
  assignmentId,
  pageSize,
  ...filter
}: {
  assignmentId: number
  pageSize: number
} & TLeadsFiltering) => {
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.leadsAssignmentsLeadsForAssignmentDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAssignmentsLeadsForAssignmentDetail,
  })

  return usePaginatedQuery(
    queryKey.leadsForAssignment(assignmentId, filter),
    async query => {
      const result = await queryFn(assignmentId, {
        search: filter.searchString.valueDebounced,
        contact_person_search: filter.name.valueDebounced,
        organization_name_search: filter.companyName.valueDebounced,
        "country_ids[]": filter.countries.valueDebounced.map(opt => opt.value),
        "positions[]": filter.positions.valueDebounced.map(opt => opt.value),
        "segments[]": filter.segments.valueDebounced.map(opt => opt.value),
        "cities[]": filter.cities.valueDebounced.map(opt => opt.value),
        phone_number_present: filter.phone.valueDebounced,
        email_present: filter.email.valueDebounced,
        contacted_already: filter.contactedByCompany.valueDebounced,
        "company_sizes[]": filter.companySizes.valueDebounced.map(opt => opt.value),
        "ids[]": filter.checkedRowsForQuery as number[],
        sort_by: columnToApi(filter.orderBy?.column),
        sort_direction: directionToApi(filter.orderBy?.direction),
        ...query,
      })

      if (!filter.allFilters.isActive) {
        result.data.leads = []
      }

      return result
    },
    {pageSize, enabled: assignmentId != null}
  )
}

export const useLeadsMetadataQuery = () =>
  useQuery({queryKey: queryKey.leadsMetadata, queryFn: async () => api.leads.indexMetadataList()})

export const getLeadsAutocompleteQuery = (field: ALeadAutocompleteFields) => {
  return (searchString: string) => {
    return useQuery({
      queryKey: queryKey.leadsAutocomplete(field, searchString),
      queryFn: searchString
        ? async () => (await api.leads.autocompleteValuesList({field, value: searchString})).data.values
        : skipToken,
    })
  }
}

export const useLeadsSegmentAutocompleteQuery = (searchString: string) => {
  return useQuery({
    queryKey: queryKey.leadsAutocomplete(ALeadAutocompleteFields.Segment, searchString),
    queryFn: searchString
      ? async () =>
          (
            await api.leads.autocompleteValuesList({field: ALeadAutocompleteFields.Segment, value: searchString})
          ).data.values.map(segment => ({
            name: i18n.t(enumTranslKey("SegmentFilter", segment.value), segment.name),
            value: segment.value,
          }))
      : skipToken,
  })
}

export const useLeadsCountryAutocompleteQuery = (searchString: string) => {
  return useQuery({
    queryKey: queryKey.leadsAutocomplete(ALeadAutocompleteFields.Country, searchString),
    queryFn: searchString
      ? async () =>
          (
            await api.leads.autocompleteValuesList({field: ALeadAutocompleteFields.Country, value: searchString})
          ).data.values.map(country => ({name: country.name, value: Number(country.value)}))
      : skipToken,
  })
}

export const useLeadDetailQuery = (leadId: number | undefined) =>
  useQuery({
    queryKey: queryKey.leadDetail(leadId),
    queryFn: leadId == null ? skipToken : async () => (await api.leads.leadsDetail(leadId)).data.lead,
    enabled: leadId != null,
  })

export const useLeadsImportersQuery = ({pageSize}: {pageSize: number}) =>
  usePaginatedQuery(queryKey.leadsImporters, query => api.leads.importersList(query), {pageSize})

export const useUploadLeadsMutation = () => {
  const queryClient = useQueryClient()

  const path = useValueByUserType({
    [AUserTypes.Admin]: "leads/import",
    [AUserTypes.SalesPerson]: "leads/sales_person_import",
  })

  return useMutation({
    mutationFn: (data: {ownership_level: AOwnershipLevels; source: string; leads_file: File}) =>
      multipartRequest({
        path,
        method: "POST",
        data: {leads_import: data},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsImportersGeneralKey})
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
    },
  })
}

export const useUpdateLeadMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (lead: ALeadDetail): Promise<AxiosResponse<{lead: ALeadDetail; meta: TMetaWithWarnings}>> =>
      api.leads.leadsPartialUpdate(lead.id, {lead: valuesToApi(lead)}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
    },
  })
}

export const useDeleteLeadMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({id, strategy}: {id: number; strategy: ALeadDeleteStrategies}) =>
      api.leads.leadsDelete(id, {delete_strategy: strategy}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
    },
  })
}

export const useBulkDeleteLeadsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ids, strategy}: {ids: number[]; strategy: ALeadDeleteStrategies}) =>
      api.bulkActions.leadsDelete({ids, delete_strategy: strategy}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
    },
  })
}

export const useRollbackLeadsUploadMutation = () => {
  const queryClient = useQueryClient()
  const rollbackImporterMutation = useRollbackImporterMutation()

  return useMutation({
    mutationFn: (importerId: number) => rollbackImporterMutation.mutateAsync(importerId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsImportersGeneralKey})
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
    },
  })
}

export const useAddProspectFromLeadMutation = () => {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.leadsAssignmentsAddProspectFromLeadCreate,
    [AUserTypes.SalesPerson]: api.salesPeople.leadsAssignmentsAddProspectFromLeadCreate,
  })

  return useMutation({
    mutationFn: ({assignmentId, leadId}: {assignmentId: number; leadId: number}) =>
      mutationFn(assignmentId, {lead_id: leadId}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
      queryClient.invalidateQueries({queryKey: prospectsGeneralTablesKey})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useRemoveProspectFromLeadMutation = () => {
  const queryClient = useQueryClient()
  const deleteProspectMutation = useDeleteProspectMutation()

  return useMutation({
    mutationFn: (prospectId: number) => deleteProspectMutation.mutateAsync(prospectId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
    },
  })
}

export const useBulkAddProspectFromLeadMutation = () => {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.Admin]: api.bulkActions.adminLeadsAssignmentsAddProspectFromLeadCreate,
    [AUserTypes.SalesPerson]: api.bulkActions.salesPeopleLeadsAssignmentsAddProspectFromLeadCreate,
  })

  return useMutation({
    mutationFn: ({assignmentId, ids}: {assignmentId: number; ids: number[]}) => mutationFn(assignmentId, {ids}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
      queryClient.invalidateQueries({queryKey: prospectsGeneralTablesKey})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useBulkRemoveProspectFromLeadMutation = () => {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.Admin]: api.bulkActions.adminLeadsAssignmentsDeleteProspectFromLeadDelete,
    [AUserTypes.SalesPerson]: api.bulkActions.salesPeopleLeadsAssignmentsDeleteProspectFromLeadDelete,
  })

  return useMutation({
    mutationFn: ({assignmentId, ids}: {assignmentId: number; ids: number[]}) => mutationFn(assignmentId, {ids}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

const valuesToApi = (values: ALeadDetail): ALeadAttributes => ({
  ...values,
  country_id: values.country?.id,
})
