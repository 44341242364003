import React from "react"
import {useTranslation} from "react-i18next"
import {PencilSquareIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../components/Alert"
import {Link} from "../../components/Link"
import {Loading} from "../../components/Loading"
import {ProfileImage} from "../../components/ProfileImage.tsx"
import {TerseFormLayout} from "../../components/TerseFormLayout"
import {useCompanyQuery} from "../../queries/companies"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {addHttpToURL} from "../../utils"
import {useDocumentTitle, useLoadPicture, useNumParam} from "../../utils/hooks"
import {isCompanyUser} from "../../utils/types"

export const CompanyPreview: React.FC = () => {
  const {t} = useTranslation()

  const companyId = useNumParam("companyId")

  const {error, data, refetch} = useCompanyQuery(companyId)
  const {user} = useUserSettingsOrLogout()

  useDocumentTitle(data?.profile.name ?? t("T_Company profile"))

  const canEdit = isCompanyUser(user) && user.company.id === companyId

  const profilePictureUrl = data?.profile.profile_picture_url ?? ""

  const {isLoading} = useLoadPicture(profilePictureUrl)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return (
    <div className={"mt-8 flex justify-center p-10 pt-0"}>
      <div className={"flex max-w-full grow flex-col items-center gap-8 xl:max-w-screen-xl"}>
        <div className={"flex w-full flex-col items-center break-all text-center"}>
          {isLoading && profilePictureUrl ? (
            <div className={"size-40 shrink-0 rounded-full bg-cr-grey-5"}>
              <Loading size={"xl"} delayShow={false} />
            </div>
          ) : (
            <ProfileImage className={"size-40 shrink-0"} src={profilePictureUrl} />
          )}
          <h1 className={"text-2xl font-semibold"}>{data.profile.name}</h1>
          <h2 className={"mt-3 whitespace-pre-wrap"}>{data.profile.headline}</h2>
        </div>

        <div className={"card w-full"}>
          <TerseFormLayout>
            <TerseFormLayout.Label>{t("T_Company name")}</TerseFormLayout.Label>
            <div>{data.profile.name}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Website")}</TerseFormLayout.Label>
            <div>
              {data.profile.website && (
                <Link variant={"neutral"} to={addHttpToURL(data.profile.website)} target={"_blank"} rel={"noreferrer"}>
                  {data.profile.website}
                </Link>
              )}
            </div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Country")}</TerseFormLayout.Label>
            <div>{data.profile.country?.name}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Industry")}</TerseFormLayout.Label>
            <div>{data.profile.industry}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Number of employees")}</TerseFormLayout.Label>
            <div>{data.profile.number_of_employees}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Description")}</TerseFormLayout.Label>
            <div className={"whitespace-pre-wrap"}>{data.profile.description}</div>

            {canEdit && (
              <>
                <TerseFormLayout.Separator invisible />
                <div className={"col-start-2"}>
                  <Link to={`/company/${companyId}/profile`} className={"inline-flex gap-2"} variant={"neutral"}>
                    <PencilSquareIcon className={"w-5"} /> {t("T_Change profile info")}
                  </Link>
                </div>
              </>
            )}
          </TerseFormLayout>
        </div>
      </div>
    </div>
  )
}
